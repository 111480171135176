/* eslint-disable */
import React, {useEffect, useState} from 'react';
import {apiGet, apiPost} from '../../../../api';
import {Select} from '../../../common/ui/select';
import {useForm} from 'react-hook-form';
import {Input} from '../../../common/ui/input';
import {Button} from '../../../common/ui/button';
import Spinner from '../../../common/spinner';
import {Modal} from '../../../common/ui/modal';
import {Col} from 'reactstrap';
import {Icon} from "../../../common/icon";
import {Badge} from "../../../common/badge";
import ReactPaginate from "react-paginate";

export const ExchangerPage = () => {
    const {register, setValue, watch} = useForm();

    // For Select arrays
    const [availableSourcesFrom, setAvailableSourcesFrom] = useState(null)
    const [pairForChange, setPairForChange] = useState(null)

    const [source, setSource] = useState(null)
    const [destination, setDestination] = useState(null)
    const [amount, setAmount] = useState(0)

    const [exchangeRate, setExchangeRate] = useState(null)

    const [isSpinnerForChange, setIsSpinnerForChange] = useState(false)

    const [error, setError] = useState(null)

    // modals
    const [alertText, setAlertText] = useState('')
    const [alertModal, setAlertModal] = useState(false)

    const [isRateHasSpinner, setIsRateHasSpinner] = useState(false)
    const [changeListResponse, setChangeListResponse] = useState(null);
    const [page, setPage] = useState(1);

    const closeModal = () => {
        setAlertModal(false)

        setValue('amountTo', '')
        setValue('amountFrom', '')
        setError(null)
    }

// Получить все валюты для обмена
    const getAvailableSources = () => {
        apiGet({
            url: '/change/available-sources'
        }).then(res => {
            if (res.error)
                return;

            let arr = [];
            res.data.map((item) => {
                let dataObject = {};
                dataObject.label = item.name;
                dataObject.value = item.name;
                dataObject.balance = item.balance;
                // dataObject.balance = item.balance;
                arr.push(dataObject);
            });

            apiGet({
                url: `/change?source=${arr[0].label}`
            }).then(res => {
                if (res.error)
                    return;
                let arrDest = [];
                res.data.map(item => {
                    const data = {};
                    data.label = item.name;
                    data.value = item.name;
                    data.balance = item.balance;
                    arrDest.push(data);
                });
                setDestination(arrDest[0].label);
                setPairForChange(arrDest);
                getChangeRate(arr[0].label, arrDest[0].label)
            });

            setAvailableSourcesFrom(arr);
            setSource(arr[0].label)
        });
    };

    useEffect(() => {
        getAvailableSources()
    }, [])

    useEffect(() => {
        if (source === null)
            return;

        apiGet({
            url: `/change?source=${source}`
        }).then(res => {
            if (res.error)
                return;
            let arrDest = [];
            res.data.map(item => {
                const data = {};
                data.label = item.name;
                data.value = item.name;
                data.balance = item.balance;
                arrDest.push(data);
            });
            setPairForChange(arrDest);
            setDestination(arrDest[0].label);
            getChangeRate(source, arrDest[0].label)
        });

    }, [source])

    useEffect(() => {
        actualizeChangeList()
    }, [page]);

    const actualizeChangeList = () => {
        apiGet({
            url: `/change/list?limit=10&page=${page}`
        }).then(res => {
            if (res.error)
                return;

            setChangeListResponse(res);
        });
    }

    const pageChanging = (data) => {
        setPage(data.selected + 1);
    };

    const changeSelectFrom = (e) => {
        setSource(e.target.value)
        setValue('amountFrom', 0)
        setValue('amountTo', '')
    }

    const getChangeRate = (from = null, to = null, _amount = null) => {
        let a = null;
        if (_amount !== null) {
            a = _amount;
        } else {
            a = amount;
        }

        setIsRateHasSpinner(true)
        if (from === null)
            from = source
        if (to === null)
            to = destination

        apiGet({
            url: `/change/rate?source=${from}&destination=${to}&amount=${a}`
        }).then(res => {
            if (res.error) {
                return setIsRateHasSpinner(false)
            }
            // setError(res.data)
            setExchangeRate(res.data);
            setIsRateHasSpinner(false)
        });
    };

    const showMeMore = () => {
        console.log(watch('selectTransactionTo'))
        console.log(destination)
    }

    const onChangeInputFrom = (e) => {
        setAmount(e.target.value)
        setValue('amountTo', exchangeRate * e.target.value)
        getChangeRate(null, null, e.target.value);
        // setExchangeCurrency(exchangeRate * e.target.value)
    }

    // useEffect(() => {
    //     if (source === null)
    //         return
    //     if (destination === null)
    //         return
    //     getChangeRate()
    // }, [amount])

    const maxSendValueSum = () => {
        let value = null
        availableSourcesFrom.map(item => {
            if (item.label === source) {
                return value = item.balance
            }
        })
        setValue('amountFrom', value)
        setValue('amountTo', value * exchangeRate)
        // setExchangeCurrency(value * exchangeRate)
    }

    const onChangeInputTo = (e) => {
        // setExchangeCurrency(e.target.value / exchangeRate)
        setValue('amountFrom', e.target.value / exchangeRate)
    }

    const getAvailableSourcesPrices = () => {
        apiGet({
            url: '/change/available-sources'
        }).then(res => {
            if (res.error)
                return;

            let arr = [];
            res.data.map((item) => {
                let dataObject = {};
                dataObject.label = item.name;
                dataObject.value = item.name;
                dataObject.balance = item.balance;
                // dataObject.balance = item.balance;
                arr.push(dataObject);
            });

            apiGet({
                url: `/change?source=${source}`
            }).then(res => {
                if (res.error)
                    return;
                let arrDest = [];
                res.data.map(item => {
                    const data = {};
                    data.label = item.name;
                    data.value = item.name;
                    data.balance = item.balance;
                    arrDest.push(data);
                });
                setPairForChange(arrDest);
                // getChangeRate(arr[0].label, arrDest[0].label)
            });
            setAvailableSourcesFrom(arr);
        });
    }

    const toChange = () => {
        const data = {};
        data.amount = Number(watch('amountFrom'));
        data.source = source;
        data.destination = destination;
        // data.destination = watch('selectTransactionTo');

        setIsSpinnerForChange(true)

        apiPost({
            url: '/change/change',
            postData: data
        }).then(res => {
            if (res.error) {
                setIsSpinnerForChange(false);
                return setError(res.data);
            }
            setValue('amountFrom', '');
            setError(null);
            setIsSpinnerForChange(false);
            getAvailableSourcesPrices()
            actualizeChangeList()
            if (res.data) {
                setAlertText('Обмен произведен успешно');
                setAlertModal(true);
            }
        });
    }

    const onChangeSelectTo = (e) => {
        setDestination(e.target.value)
        getChangeRate(source, e.target.value)
        setValue('amountFrom', 0)
        setValue('amountTo', '')
    }

    return (
        <div>
            <div className='w-full flex justify-center mt-20'>
                <div>
                    {/*<button onClick={showMeMore}>show</button>*/}

                    <div className='justify-center'>
                        <div>
                            {/*Start --------- Перевод со счета -------------*/}
                            <div className='w-[70vw] max-w-xl bg-white p-4'>
                                <div className='flex justify-between'>
                                    <div className='my-auto'>Со счета</div>
                                    {availableSourcesFrom
                                        ? <div>
                                            <Select
                                                fieldClass='w-full'
                                                list={availableSourcesFrom}
                                                onChange={(e) => changeSelectFrom(e)}
                                                name={'selectTransactionFrom'}
                                                register={register}/>
                                        </div>
                                        : <Spinner styles={{display: 'flex', height: 50}}/>
                                    }
                                </div>

                                <div className='flex justify-between mt-4'>
                                    <Input
                                        fieldClass={'w-full mt-auto mb-0'}
                                        type="number"
                                        name="amountFrom"
                                        onChange={(e) => onChangeInputFrom(e)}
                                        placeholder='количество'
                                        register={register}/>

                                    <div className={'mt-auto mb-0'}>
                                        <Button
                                            className={'ml-2 h-10'}
                                            onClick={maxSendValueSum}
                                            color="primary">Все</Button>
                                    </div>
                                </div>
                                {availableSourcesFrom
                                    ? availableSourcesFrom?.map(item => {
                                        if (item.label === source) {
                                            return (
                                                <div
                                                    key={item.label}
                                                    className='mt-2 font-bold'>Баланс: {item.balance} {item.label}</div>
                                            )
                                        }
                                    })
                                    : <Spinner styles={{display: 'flex', height: 25}}/>
                                }
                            </div>
                        </div>
                        <div className='flex justify-center my-4'>
                            <Icon name="arrowDownDouble" size={35}/>
                        </div>
                        {/*End --------- Перевод со счета -------------*/}

                        {/*Start --------- Перевод на счета -------------*/}
                        <div className='my-6'>
                            <div className='w-[70vw] max-w-xl bg-white p-4'>
                                <div className='flex justify-between'>
                                    <div className='my-auto'>На счет</div>
                                    {pairForChange
                                        ? <div>
                                            <Select
                                                fieldClass='w-full'
                                                list={pairForChange}
                                                name={'selectTransactionTo'}
                                                onChange={(e) => onChangeSelectTo(e)}
                                                register={register}/>
                                        </div>
                                        : <Spinner styles={{display: 'flex', height: 50}}/>
                                    }
                                </div>
                                <div className='flex justify-between mt-4'>
                                    {/*<div className='my-auto'>Вы получите:</div>*/}
                                    <Input
                                        label='Вы получите:'
                                        fieldClass='w-full'
                                        type='number'
                                        name='amountTo'
                                        // value={exchangeCurrency}
                                        onChange={(e) => onChangeInputTo(e)}
                                        register={register}
                                    />
                                </div>
                                <div>
                                    {pairForChange
                                        ? pairForChange?.map(item => {
                                            if (item.label === destination) {
                                                return (
                                                    <div
                                                        key={item.label}
                                                        className='mt-2 font-bold'>Баланс: {item.label === 'FIAT RUB' ? item?.balance.toFixed(2) + ' ' + item.label : item.balance + ' ' + item.label}</div>
                                                )
                                            }
                                        })
                                        : <Spinner styles={{display: 'flex', height: 25}}/>
                                    }
                                </div>
                            </div>
                        </div>
                        {/*End --------- Перевод на счета -------------*/}

                    </div>

                    <div className='w-[70vw] max-w-xl flex justify-between mb-2 bg-white p-4'>
                        <div>Курс обмена</div>
                        {exchangeRate && source && destination && !isRateHasSpinner
                            ? <div>{exchangeRate.toFixed(5)} {source}</div>
                            : <Spinner styles={{display: 'flex', height: 25}}/>
                        }
                    </div>

                    <div className='error mt-2'>
                        {error?.amount && error?.amount?.map((item, i) =>
                            <div key={i}>{item}</div>
                        )}
                        {typeof error === 'string' ? error : <></>}
                    </div>

                    {isSpinnerForChange ?
                        <Spinner styles={{margin: 'auto', display: 'flex', height: 50}}/> :
                        <Button className='h-16 w-[70vw] max-w-xl justify-center' onClick={toChange}
                                color='primary'>Обмен</Button>
                    }
                </div>

                {/*Start ------- Открыть окно уведомления*/}
                <Modal
                    title={alertText}
                    onClose={closeModal}
                    isOpen={alertModal}
                    headerStyles={{border: 'none', color: '#000000'}}
                    footerStyles={{border: 'none', padding: '10px'}}
                    settings="alert"
                    bodyStyles={{padding: '0px'}}
                    modalStyles={{margin: 'auto'}}
                    footer={
                        <div className="flex justify-center">
                            <Col xs={'auto'}>
                                <Button onClick={closeModal} color="primary">
                                    Ок
                                </Button>
                            </Col>
                        </div>
                    }/>
                {/*End ------- Открыть окно уведомления*/}


            </div>

            <div className='mt-5'>
                {changeListResponse === null ?
                    <div className='w-full flex m-auto'><Spinner styles={{margin: 'auto', height: 50}}/></div> :
                    <>
                        <div className={'overflow-x-auto'}>
                            <table className="table">
                                <thead>
                                <tr>
                                    <th>№</th>
                                    <th>Пара</th>
                                    <th>Отправлено на обмен</th>
                                    <th>К получению</th>
                                    <th>Время</th>
                                    <th>Статус</th>
                                </tr>
                                </thead>

                                <tbody>
                                {changeListResponse?.data?.models?.map((i) => (
                                    <tr key={i.id}>
                                        <td>{i.id}</td>
                                        <td>{i.source} => {i.destination}</td>
                                        <td>{i.amount_source}</td>
                                        <td>{i.amount_destination_to_user}</td>
                                        <td>{i.createdAt}</td>
                                        <td>
                                            <Badge color={i.isCompleted ? 'green' : 'gray'}>
                                                {i.isCompleted ? 'Выполнено' : 'В процессе'}
                                            </Badge>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="text-center mt-2">
                            <ReactPaginate
                                pageCount={changeListResponse?.data?.lastPage === 1 ? 0 : changeListResponse?.data?.lastPage}
                                pageRangeDisplayed={5}
                                marginPagesDisplayed={3}
                                onPageChange={pageChanging}
                                activeClassName={'_active'}
                                containerClassName={'cmp-pagination inline-flex'}
                                pageLinkClassName={'flex items-center justify-center'}
                                pageClassName={'flex items-center justify-center'}
                                previousLabel={false}
                                nextLabel={false}
                                forcePage={page ? +page - 1 : 0}/>
                        </div>
                    </>
                }
            </div>
        </div>


    )
}
