import React from 'react';
import {Col, Row} from 'reactstrap';
import {useFetch} from '../../../hooks/useFetch';

export const GarantOffices = () => {
  const { response } = useFetch({
    url: '/garant/offices',
  });

  return (
    <Row>
      <Col xs={12}>
        <div className="text-lg font-medium mb-2">Наши офисы</div>
      </Col>
      <Col xs={12}>
        <div className="mb-2">
          {
            response ? response.data.map(office =>
              <Col key={office.address}>
                <div><b>Адрес:</b> {office.address}</div>
                <div><b>Комиссия:</b> {office.fee}%</div>
                {office.phone ? <div><b>Номер телефона:</b> {office.phone}</div> : <></>}
                <div><b>Telegram:</b>{' '}
                  <a
                    href={office.tg}
                    target="_blank"
                    rel="noreferrer"
                    className="link">
                    {office.tg}
                  </a>
                  <br/><br/>
                </div>
              </Col>
            ) : <></>
          }
        </div>
      </Col>
    </Row>
  );
};

