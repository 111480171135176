/* eslint-disable */
import React, {useEffect, useState} from 'react';
// import {useHistory, useParams} from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import {Col, Row} from "reactstrap";
import {useForm} from "react-hook-form";
import {Button} from "../../../../common/ui/button";
// import {CopyToClipboard} from "react-copy-to-clipboard";
import Spinner from "../../../../common/spinner";
import {apiGet} from "../../../../../api";
import {Icon} from "../../../../common/icon";
import {Input} from "../../../../common/ui/input";
import {Badge} from "../../../../common/badge";
import {Select} from "../../../../common/ui/select";
import ReactTooltip from "react-tooltip";

export const EachFiatWalletPage = ({
                                       openModalFiatOutPayming,
                                       eachWalletName,
                                       isRefreshWallet,
                                       // nameForEachWalletPage,
                                       setIsShowEachFiatWalletPage,
                                       openModalFiatEnterOrder,
                                       openModalBalanceOut,
                                       inputFiat,
                                       outputFiat,
}) => {

    const [historyWalletList, setHistoryWalletList] = useState(null);
    const [page, setPage] = useState(1);
    const [fiatPage, setFiatPage] = useState(1);
    const {register, watch, reset} = useForm();

    // Поиск по таблице
    const [searchPurpose, setSearchPurpose] = useState('');
    const [searchFrom, setSearchFrom] = useState('');
    const [searchTo, setSearchTo] = useState('');
    const [transactionType, setTransactionType] = useState('');

    // Переключение между истории и списком заявок
    const [choseHistory, setChoseHistory] = useState(false);

    //Список всех заявок по кошельку
    const [fiatWalletRequest, setFiatWalletRequest] = useState(null);


    const [historyFiatWalletList, setHistoryFiatWalletList] = useState(0);

    const [isHistory, setIsHistory] = useState(true);

    // Список всех кошельков
    // const [walletsData, setWalletsData] = useState([]);

    // const history = useHistory()


    const pageChanging = (data) => {
        setPage(data.selected + 1);
    };

    const pageFiatChanging = (data) => {
        setFiatPage(data.selected + 1);
    };

    const findHistory = () => {
        setSearchPurpose(watch('searchPurpose'))
        setSearchFrom(watch('searchFrom'))
        setSearchTo(watch('searchTo'))
        setTransactionType(watch('transactionType'))
    };

    const clearSearchedInputs = () => {
        reset({
            searchPurpose: '',
            searchFrom: '',
            searchTo: '',
            transactionType: '',
        });
        setSearchPurpose('');
        setSearchFrom('');
        setSearchTo('');
        setTransactionType('');
    }

    const showOuterLink = (data) => {
        apiGet({
            url: `/wallet/transaction-link?name=${eachWalletName.name}&id=${data.id}`
        }).then(res => {
            if (res.error)
                return console.log(res.data);
            window.open(res.data, '_blank', 'noopener,noreferrer');
        });
    };

    const getHistoryWallet = () => {
        apiGet({
            url: `/wallet/history?name=${eachWalletName.name}&limit=10&page=${page}&comment=${searchPurpose}&type=${transactionType}&periodStart=${searchFrom}&periodEnd=${searchTo}`
        }).then(res => {
            if (res.error)
                return console.log(res.data);
            setHistoryWalletList(res.data);
        });
    }

    useEffect(() => {
        setHistoryWalletList(null)
        getHistoryWallet()
    }, [page, searchPurpose, searchFrom, searchTo, transactionType, isRefreshWallet]);

    const backToWallet = () => {
        setIsShowEachFiatWalletPage(false)
        // history.push("/wallet")
    }

    const getFiatTale = () => {
        apiGet({
            url: `/fiat-output?wallet=${eachWalletName.name}&page=${fiatPage}`
        }).then(res => {

            if (res.error)
                return
            setHistoryFiatWalletList(res.data.lastPage)
            setFiatWalletRequest(res.data.models)
        })
    }

    useEffect(() => {
        getFiatTale()
    }, [fiatPage])

    // useEffect(() => {
    //     // if (commissionSend === null)
    //     //     getCommission();
    //
    //     // Получить список всех крипто кошельков
    //     apiGet({
    //         url: `/wallet/crypto`
    //     }).then(res => {
    //         if (res.error) return
    //         res.data.map(item => {
    //             if (item.name === nameForEachWalletPage)
    //                 setWalletsData(item)
    //         })
    //     })
    //
    //     // Получить список usdr кошелек
    //     if (nameForEachWalletPage === 'USDR') {
    //         apiGet({
    //             url: `/wallet/usdr`
    //         }).then(res => {
    //             if (res.error) {
    //                 return
    //             }
    //             setWalletsData(res.data)
    //         })
    //     }
    //
    // }, [])


    const refreshHistoryTable = () => {
        setHistoryWalletList(null)
        getHistoryWallet()
    }

    const refreshHistoryFiatTable = () => {
        getFiatTale()
    }

    return (
        <div className="m-5">
            <div className='flex'>
                {/*<button onClick={() => console.log(nameForEachWalletPage)}>show</button>*/}
                <Button
                    className='mr-6'
                    onClick={backToWallet}
                    color="primary">
                    <Icon name="arrowLeft" size={20} color='white'/>
                    назад
                </Button>

                {historyWalletList !== null &&
                    <div className='flex gap-3'>
                                <button
                                    data-for="setBalance"
                                    data-tip={'Запросить ввод'}
                                    // onClick={() => openModalFiatEnterOrder(eachWalletName.name)}
                                    onClick={() => inputFiat(eachWalletName)}
                                    className="items-center btn-primary rounded-md px-2">
                                    <div className='sm:hidden'>
                                        <Icon name="walletIn" size={15} color='white'/>
                                    </div>
                                    <div className='hidden sm:block'>
                                        <Icon name="walletIn" size={22} color='white'/>
                                    </div>
                                </button>

                        <button
                            data-for="setBalance"
                            data-tip={'Вывод фиата'}
                            // onClick={() => openModalFiatOutPayming(eachWalletName.name)}
                            onClick={() => outputFiat(eachWalletName)}
                            className="items-center btn-primary rounded-md px-2">
                            <div className='sm:hidden'>
                                <Icon name="walletOut" size={15} color='white'/>
                            </div>
                            <div className='hidden sm:block'>
                                <Icon name="walletOut" size={22} color='white'/>
                            </div>
                        </button>

                        <button
                          data-for="setBalance"
                          data-tip={'Отправить'}
                          onClick={() => openModalBalanceOut(eachWalletName.name, eachWalletName.balance)}
                          className="items-center sm:text-text text-sm btn-primary rounded-md px-2">
                            <div className='sm:hidden'>
                                <Icon name="walletSend" size={15} color='white'/>
                            </div>
                            <div className='hidden sm:block'>
                                <Icon name="walletSend" size={22} color='white'/>
                            </div>
                            <ReactTooltip
                              id="setBalance"
                              globalEventOff="click"
                              className="tooltip-modal"
                              effect="solid"/>
                        </button>

                                <ReactTooltip
                                    id="setBalance"
                                    globalEventOff="click"
                                    className="tooltip-modal"
                                    effect="solid"/>
                    </div>}
            </div>
            <div className={'sm:text-2xl text-lg font-medium my-5'}>
                История операций по кошельку {eachWalletName.name}
            </div>

            <Row className="items-end justify-start mt-5">
                <Col xs="auto">
                    <Input
                        label="Назначение"
                        name="searchPurpose"
                        register={register}/>
                </Col>
                <div className={'flex flex-wrap'}>

                    <Col xs="auto">
                        <Input
                            // value={'2019-01-01'}
                            inputClass={'min-h-[2.5rem] min-w-[8rem]'}
                            // fieldClass={'min-h-[2.5rem] min-w-[8rem]'}
                            label="Искать с"
                            type="date"
                            name="searchFrom"
                            register={register}/>
                    </Col>
                    <Col xs="auto">
                        <Input
                            // value={'2023-01-01'}
                            inputClass={'min-h-[2.5rem] min-w-[8rem]'}
                            // fieldClass={'min-h-[2.5rem] min-w-[8rem]'}
                            label="Искать до"
                            type="date"
                            name="searchTo"
                            register={register}/>

                    </Col>
                </div>
                <Col xs="auto">
                    <Select
                        label="Тип транзакции"
                        list={[
                            {label: 'Все', value: ''},
                            {label: 'Поступление', value: '1'},
                            {label: 'Списание', value: '2'},
                        ]}
                        name="transactionType"
                        register={register}/>
                </Col>
            </Row>

            <div className='flex justify-between'>
                <div>
                    <Button
                        onClick={findHistory}
                        color="primary"
                        className='mr-2'
                        // style={{marginBottom: 15}}
                    >Поиск</Button>
                    <Button
                        onClick={clearSearchedInputs}
                        color="primary"
                        className='my-2'
                        // style={{marginBottom: 15, marginLeft: 10}}
                    >Очистить</Button>
                </div>
                {isHistory ?
                <div>
                    <Button
                        className='mt-2 ml-2'
                        onClick={refreshHistoryTable}
                        color="primary">Обновить</Button>
                </div> :
                    <div>
                        <Button
                            className='mt-2 ml-2'
                            onClick={refreshHistoryFiatTable}
                            color="primary">Обновить</Button>
                    </div>}
            </div>

            <div className='mt-5'>
                <Button onClick={() => {
                    setIsHistory(true)
                    setChoseHistory(false)
                }} color='primary mr-2'>История</Button>
                {historyWalletList !== null &&
                <Button onClick={() => {
                    setIsHistory(false)
                    setChoseHistory(true)
                }} color='primary' className='mt-2 sm:ml-2'>Список заявок на вывод фиата</Button>}
            </div>

            {!choseHistory ?
                <>
            {historyWalletList === null ?
                <div className='w-full flex m-auto'><Spinner styles={{margin: 'auto', height: 50}}/></div> :
                <>
                    <div className={'overflow-x-auto'}>
                        <table className="table">
                            <thead>
                            <tr>
                                <th>№</th>
                                <th>НАЗНАЧЕНИЕ</th>
                                <th className="whitespace-nowrap">ВРЕМЯ ОПЕРАЦИИ</th>
                                <th>Подтверждение</th>
                                <th className="whitespace-nowrap">СУММА</th>
                                <th className="whitespace-nowrap"></th>
                            </tr>
                            </thead>

                            <tbody>
                            {historyWalletList?.data?.map((i) => (
                                <tr key={i.id}>
                                    <td>{i.id}</td>
                                    <td>{i.comment}</td>
                                    <td className="whitespace-nowrap">{i.createdAt}</td>
                                    <td>
                                        <Badge color={i.isConfirmed ? 'primary' : 'danger'}>
                                            {i.isConfirmed ? 'Подтверждено' : 'Не подтверждено'}
                                        </Badge>
                                    </td>
                                    <td className="whitespace-nowrap">{i.amount}</td>
                                    <td className="whitespace-nowrap">
                                        {i.isExternal ?
                                            <button onClick={() => showOuterLink(i)}>
                                                <Icon size={24} name="referalLink"/>
                                            </button> : <></>}
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="text-center mt-2">
                        <ReactPaginate
                            pageCount={historyWalletList?.lastPage === 1 ? 0 : historyWalletList?.lastPage}
                            pageRangeDisplayed={5}
                            marginPagesDisplayed={3}
                            onPageChange={pageChanging}
                            activeClassName={'_active'}
                            containerClassName={'cmp-pagination inline-flex'}
                            pageLinkClassName={'flex items-center justify-center'}
                            pageClassName={'flex items-center justify-center'}
                            previousLabel={false}
                            nextLabel={false}
                            forcePage={page ? +page - 1 : 0}/>
                    </div>
                </>
            }
            </> : <>
                        <div className={'overflow-x-auto'}>
                            <table className="table">
                                <thead>
                                <tr>
                                    <th>№</th>
                                    <th className="whitespace-nowrap">ВРЕМЯ ОПЕРАЦИИ</th>
                                    <th>Статус</th>
                                    <th className="whitespace-nowrap">СУММА</th>
                                    <th className="whitespace-nowrap">К выплате с учетом комиссии</th>
                                    <th className="whitespace-nowrap">Кошелек</th>
                                    {/*<th className="whitespace-nowrap"></th>*/}
                                </tr>
                                </thead>

                                <tbody>
                                {fiatWalletRequest?.map((i) => (
                                    <tr key={i.id}>
                                        <td>{i?.id}</td>
                                        <td className="whitespace-nowrap">{i?.createdAt}</td>
                                        <td>
                                            <Badge color={i?.status === 1 ? 'gray' : i?.status === 2 ? 'green' : 'danger'}>
                                                {i?.statusText}
                                            </Badge>
                                        </td>
                                        {/*<td>*/}
                                        {/*    <Badge color={i?.isAccepted ? 'primary' : 'danger'}>*/}
                                        {/*        {i?.isAccepted ? 'Подтверждено' : 'Не подтверждено'}*/}
                                        {/*    </Badge>*/}
                                        {/*</td>*/}
                                        <td className="whitespace-nowrap">{i?.amount}</td>
                                        <td className="whitespace-nowrap">{i?.to_output}</td>
                                        <td className="whitespace-nowrap">{i?.wallet}</td>
                                        {/*<td className="whitespace-nowrap">*/}
                                        {/*    {i.isExternal ?*/}
                                        {/*        <button onClick={() => showOuterLink(i)}>*/}
                                        {/*            <Icon size={24} name="referalLink"/>*/}
                                        {/*        </button> : <></>}*/}
                                        {/*</td>*/}
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="text-center mt-2">
                            <ReactPaginate
                                pageCount={historyFiatWalletList === 1 ? 0 : historyFiatWalletList}
                                pageRangeDisplayed={5}
                                marginPagesDisplayed={3}
                                onPageChange={pageFiatChanging}
                                activeClassName={'_active'}
                                containerClassName={'cmp-pagination inline-flex'}
                                pageLinkClassName={'flex items-center justify-center'}
                                pageClassName={'flex items-center justify-center'}
                                previousLabel={false}
                                nextLabel={false}
                                forcePage={fiatPage ? +fiatPage - 1 : 0}/>
                        </div>
                    </>}
        </div>
    );
};
