import { LoginPage } from '../components/pages/auth/login';
import { PassRecoverPage } from '../components/pages/auth/passRecover';
import { RegistrationPage } from '../components/pages/auth/registration';
// import { FastLaunchPage1 } from '../components/pages/fastLaunch/1';
// import { FastLaunchPage2 } from '../components/pages/fastLaunch/2';
// import { FastLaunchPage3 } from '../components/pages/fastLaunch/3';
// import { FastLaunchPage4 } from '../components/pages/fastLaunch/4';
import { page500 } from '../components/pages/main/500';
import { AppealPage } from '../components/pages/main/appeal';
import { AppealReadPage } from '../components/pages/main/appealRead';
import { BinaryStructure } from '../components/pages/main/binaryStructure';
// import DealPage  from '../components/pages/main/deal';
import { Page404 } from '../components/pages/main/error/404';
import { FastLaunchWelldone } from '../components/pages/main/fastLaunchWelldone';
import { GarantPage } from '../components/pages/main/garant';
import { GarantInvestViewPage } from '../components/pages/main/garant/invest-view';
import { GenerationsPage } from '../components/pages/main/generations';
// import { GuessHashPage } from '../components/pages/main/guess';
import { HomePage } from '../components/pages/main/home';
import { LessonPage } from '../components/pages/main/lesson';
import { LinearStructure } from '../components/pages/main/linearStructure';
import { MaterialsPage } from '../components/pages/main/materials';
import { NotificationsPage } from '../components/pages/main/notifications';
import { Profile } from '../components/pages/main/profile';
import { ProfileVerification } from '../components/pages/main/profile/verification';
import { RsiClubPage } from '../components/pages/main/rsi-club';
// import { SmartPage } from '../components/pages/main/smart';
import { SmartInvestViewPage } from '../components/pages/main/smart/invest-view';
import { SmartInStructure } from '../components/pages/main/smartInStructure';
import { TradingWithRsi } from '../components/pages/main/tradingWithRsi';
import { TrainingPage } from '../components/pages/main/training';
// import {WalletPage} from '../components/pages/main/wallet';
import { CoursesLevel2 } from '../components/pages/main/CoursesLevel2';
import { CoursesLevel2In } from '../components/pages/main/CoursesLevel2In';
import { PasswordResetPage } from '../components/pages/auth/PasswordReset';
import PrometeiPage from '../components/pages/main/prometei';
// import { Flow } from '../components/pages/main/flow/Flow';
import WalletPageUpdate from '../components/pages/main/newWallet';
import { EachWalletPage } from '../components/pages/main/eachWallet';
import {ExchangerPage} from '../components/pages/main/exchanger';
import {IndexPage} from '../components/pages/main/index';
import {IndexTypePage} from '../components/pages/main/index-type';
import {HomePageProduct} from '../components/pages/main/home-product';

export const mainRoutes = [
  {
    path: '/',
    component: HomePage,
  },
  {
    path: '/login',
    component: HomePage,
  },
  {
    path: '/registration',
    component: HomePage,
  },
  // {
  //   path: '/smart',
  //   component: SmartPage,
  // },
  {
    path: '/wallet',
    component: WalletPageUpdate,
  },
  {
    path: '/exchanger',
    component: ExchangerPage,
  },
  {
    path: '/profile-verification',
    component: ProfileVerification,
  },
  {
    path: '/trading-with-rsi',
    component: TradingWithRsi,
  },
  {
    path: '/smart/invest-view/:id',
    component: SmartInvestViewPage,
  },
  {
    path: '/garant/invest-view/:id',
    component: GarantInvestViewPage,
  },
  {
    path: '/appeal/:id',
    component: AppealReadPage,
  },
  {
    path: '/lesson/:id',
    component: LessonPage,
  },
  {
    path: '/garant',
    component: GarantPage,
  },
  // {
  //   path: '/wallet',
  //   component: WalletPage,
  // },
  {
    path: '/materials',
    component: MaterialsPage,
  },
  // {
  //   path: '/deal',
  //   component: DealPage,
  // },
  {
    path: '/smart-in-structure',
    component: SmartInStructure,
  },
  {
    path: '/appeal',
    component: AppealPage,
  },
  {
    path: '/generations',
    component: GenerationsPage,
  },
  {
    path: '/business-package',
    component: RsiClubPage,
  },
  {
    path: '/linear-structure',
    component: LinearStructure,
  },
  {
    path: '/binary-structure',
    component: BinaryStructure,
  },
  {
    path: '/profile',
    component: Profile,
  },
  {
    path: '/training',
    component: TrainingPage,
  },
  {
    path: '/notifications',
    component: NotificationsPage,
  },
  // {
  //   path: '/guesshash',
  //   component: GuessHashPage,
  // },
  {
    path: '/fast-launch-welldone',
    component: FastLaunchWelldone,
  },
  {
    path: '/page500',
    component: page500,
  },
  {
    path: '/courses-level2',
    component: CoursesLevel2,
  },
  {
    path: '/courses-level2/:type',
    component: CoursesLevel2In,
  },
  // {
  //   path: '/prometey',
  //   component: PrometeiPage,
  // },
  // {
  //   path:'/flow',
  //   component:Flow,
  // },
  {
    path: '/wallet/:nameWallet',
    component: EachWalletPage,
  },
  {
    path: '/index',
    component: IndexPage,
  },
  {
    path: '/index/:id',
    component: IndexTypePage,
  },
  {
    path: '*',
    component: Page404,
  },
];

export const productMainRoutes = [
  {
    path: '/',
    component: HomePageProduct,
  },
  {
    path: '/login',
    component: HomePageProduct,
  },
  {
    path: '/registration',
    component: HomePageProduct,
  },
  // {
  //   path: '/smart',
  //   component: SmartPage,
  // },
  {
    path: '/wallet',
    component: WalletPageUpdate,
  },
  {
    path: '/exchanger',
    component: ExchangerPage,
  },
  {
    path: '/profile-verification',
    component: ProfileVerification,
  },
  {
    path: '/trading-with-rsi',
    component: TradingWithRsi,
  },
  {
    path: '/smart/invest-view/:id',
    component: SmartInvestViewPage,
  },
  {
    path: '/profile',
    component: Profile,
  },
  {
    path: '/notifications',
    component: NotificationsPage,
  },
  {
    path: '/page500',
    component: page500,
  },
  {
    path: '/prometey',
    component: PrometeiPage,
  },
  {
    path: '/wallet/:nameWallet',
    component: EachWalletPage,
  },
  {
    path: '/generations',
    component: GenerationsPage,
  },
  {
    path: '*',
    component: Page404,
  },
];

export const authRoutes = [
  {
    path: '/login',
    component: LoginPage,
  },
  {
    path: '/reset-pass',
    component: PasswordResetPage,
  },
  {
    path: '/registration',
    component: RegistrationPage,
  },
  {
    path: '/password-recover',
    component: PassRecoverPage,
  },
  {
    path: '/fast-launch/step-1',
    component: RegistrationPage,
  },
  {
    path: '/fast-launch/step-2',
    component: RegistrationPage,
  },
  {
    path: '/fast-launch/step-3',
    component: RegistrationPage,
  },
  {
    path: '/fast-launch/step-4',
    component: RegistrationPage,
  },
];
